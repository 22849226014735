import utility from '@/common/utility'
import auth from '@/common/auth'

export default class ExaminationModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.examinationGuid
      this.examinationGuid = props.examinationGuid
      this.examinationCode = props.examinationCode
      this.approvalUserGuid = props.approvalUserGuid
      this.approvalStatus = props.approvalStatus
      this.tenderGuid = props.tenderGuid
      this.periodGuid = props.periodGuid
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.examinationGuid) this.examinationGuid = ''
    if (!this.examinationCode) this.examinationCode = ''
    if (!this.approvalUserGuid) this.approvalUserGuid = ''
    if (!this.approvalStatus) this.approvalStatus = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.periodGuid) this.periodGuid = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.examinationGuid = utility.getUuid()
    this.resourceId = this.examinationGuid
  }
}
