import BaseService from '@/services/BaseService'
import utility from '@/common/utility'
import axios from 'axios'
import auth from '@/common/auth'

let resourceName = 'user'
let changePasswordUrl = 'VerifyUserNameAndPassword'

class UserService extends BaseService {
  changePassword (userGuid, userName, password, oldPassword) {
    return axios.post(utility.getFullUrl(this.resourceName, `${changePasswordUrl}?userGuid=${userGuid}&userName=${userName}&newPassword=${password}&oldPassword=${oldPassword}`), {}, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const userService = new UserService(resourceName)

export default userService
