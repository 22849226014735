import axios from 'axios'
import BaseService from '@/services/BaseService'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'examinationrecord'

class ExaminationRecordService extends BaseService {
  // 重写
  list (searchModel) {
    let params = {}
    Object.assign(params, searchModel)
    return axios.get(utility.getFullUrl(this.resourceName), {
      params: params,
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const examinationRecordService = new ExaminationRecordService(resourceName)

export default examinationRecordService
