<template>
  <el-timeline>
    <el-timeline-item
      v-for="examinationRecord in examinationRecords"
      :key="examinationRecord.examinationRecordGuid"
      placement="top"
      :timestamp="examinationRecord.startTime | timeFilter">
      <el-card>
        <h5>
          {{ examinationRecord.stepName }}
        </h5>
        <p>
          <span class="mr-2">{{ examinationRecord.staffName }}</span>
          <span>
            {{ examinationRecord.startTime | timeFilter }}
          </span>
          <template v-if="showEndTime(examinationRecord.endTime)">
            <span>
              -
            </span>
            <span>
              {{ examinationRecord.endTime | timeFilter }}
            </span>
          </template>
        </p>
      </el-card>
    </el-timeline-item>
  </el-timeline>
</template>

<script>
import examinationRecordService from '@/services/examinationRecordService'
import utility from '@/common/utility'

export default {
  name: 'ExaminationTimeline',
  props: {
    examinationGuid: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      examinationRecords: []
    }
  },
  filters: {
    timeFilter (_time) {
      return utility.formatTime(_time)
    }
  },
  methods: {
    _getExaminatinRecords () {
      examinationRecordService.list({ examinationGuid: this.examinationGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.examinationRecords = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    showEndTime (_time) {
      if (utility.formatTime(_time, 0) < 0) {
        return false
      }
      return true
    }
  },
  created () {
    this._getExaminatinRecords()
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-card__body {
  padding-bottom: 5px;
}
</style>
